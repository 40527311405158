<template>
  <div class="assignment-left-panel">
    <div class="left-panel-header">
      <div class="header-title">
        {{ $t('assignment.leftPanel.header') }}
      </div>
      <label class="switch-label">
        {{ $t('assignment.leftPanel.hideEmptyTimeslots') }}
      </label>
      <b-switch v-model="hideEmptyTimeslots"/>
    </div>
    <assignment-timeslot
      v-for="timeslot in filteredTimeslots"
      :key="`left_panel_timeslot_${timeslot.id}`"
      :timeslot="timeslot"
      @show-pickupp-form="(task) => $emit('show-pickupp-form', task)"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import apiWithFiltersMixin from '@/mixins/api-with-filters-mixin'
import { formatDate } from '@js/utils'
import fulfilmentChannelMixin from '@/mixins/fulfilment-channel-mixin'
import uniq from 'lodash/uniq'

const AssignmentTimeslot = () => import('./AssignmentTimeslot')

export default {
  name: 'assignment-left-panel',
  components: {
    AssignmentTimeslot
  },
  mixins: [
    apiWithFiltersMixin,
    fulfilmentChannelMixin
  ],
  data() {
    return {
      hideEmptyTimeslots: true,
      // override debounce delay in mixin
      delay: 250
    }
  },
  computed: {
    ...mapGetters('task', [
      'tasks'
    ]),
    ...mapGetters('timeslot', [
      'timeslots'
    ]),
    filteredTimeslots() {
      if (this.hideEmptyTimeslots) {
        const taskStartTimes = uniq(this.tasks.map(task => task.startTime))
        return this.timeslots.filter(timeslot => taskStartTimes.includes(timeslot.startTime))
      }
      return this.timeslots
    }
  },
  created() {
    this.resetTaskStore()
    this.resetTimeslotStore()

    this.resetAssigningTrips()
  },
  methods: {
    ...mapActions('task', [
      'resetTaskStore',
      'getIncomingTasksRequest'
    ]),
    ...mapActions('timeslot', [
      'resetTimeslotStore',
      'getTimeslotsRequest'
    ]),
    ...mapActions('trip', [
      'resetAssigningTrips'
    ]),
    // override method in mixin
    onReceived(data) {
      if (data.object === 'Task') {
        // TODO: check if will disrupt any coordinator/hub manager actions
        this.debounceRequest(this.getIncomingTasks)
      }
    },
    // override method in mixin
    apiRequest() {
      this.subscribeFulfilmentChannel()

      this.getTimeslotsRequest()
      this.getIncomingTasks()
    },
    getIncomingTasks() {
      this.getIncomingTasksRequest({
        servingDate: formatDate(this.selectedDate),
        timeRangeStart: this.selectedTimeRange[0],
        timeRangeEnd: this.selectedTimeRange[1],
        hubIds: this.selectedHubIds
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.assignment-left-panel {
  .left-panel-header {
    display: flex;
    align-items: center;
    padding: $space-xs;
    border-bottom: 1px solid $grey-darker;

    > :not(:last-child) {
      padding-right: $space-xs;
    }

    .header-title {
      flex-grow: 1;
      font-weight: bold;
    }

    label {
      @extend %small;

      &.switch-label {
        white-space: nowrap;
      }

      & + .switch {
        margin-right: 0;

        &::v-deep .control-label {
          display: none;
        }
      }
    }
  }
}
</style>
